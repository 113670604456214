import React from 'react'
import '../style.scss'

export const OntarioTermsAndConditions = () => {
    return (
        <div className="terms-content">
            <div className="terms-header">
                <h5 className="terms-title" id="exampleModalLabel">ONTARIO TERMS AND CONDITIONS</h5>
            </div>
            <div className="terms-body">
                <p>Version 1.1</p>
                <p>Effective Date: 10th January 2024</p>
                <p>
                    It is important that you (the "Affiliate", "You") read and understand these terms and conditions before using these services.
                </p>
                <p>
                    The following is an agreement between Well Played Media, Unipessoal LDA (trading as “Casino Days” and referred to as “we”, “us” or “our”, as the context requires) and you, being a legal entity applying to register as a member of the Rhino Affiliates programme (“Affiliate Programme”), which contains the terms and conditions that apply to all members of the Affiliate Programme ("Agreement").
                </p>
                <p>
                    When using any website from which this Agreement is accessible, and/or registering as an affiliate with the Affiliate Programme, you agree to be legally bound by, and comply with, the terms of this Agreement.
                </p>
                <p>
                    If you do not agree to the terms and conditions set out in this Agreement, you should immediately discontinue your application to join the Affiliate Programme.
                </p>
                <p>Affiliate Programme Terms and Conditions</p>
                <ol>
                <li><p className="title">Definitions</p></li>
                <ul>
                <li><p className="title">Affiliate</p></li>
                <p>
                    You, the person (whether an individual or a company/corporate entity/organisation) who has registered and been accepted as a participant in the Affiliate Programme.
                </p>
                <li><p className="title">Affiliate Account</p></li>
                <p>
                    An account in the name of the Affiliate on NetRefer.An account in the name of the Affiliate via which you may track your participation in the Affiliate Programme.
                </p>
                <li><p className="title">Affiliate Link/s</p></li>
                <p>
                    Internet hyperlinks used by the Affiliate to link from the Affiliate Website/s or any other third-party website to the Websites.
                </p>
                <li><p className="title">Affiliate Network</p></li>
                <p>
                    An individual and/or entity with whom you have a business relationship and who assists you in referring potential customers to the Websites, and who does not have an Affiliate Account but whose referrals are tracked via your Affiliate Account.
                </p>
                <li><p className="title">Affiliate Programme</p></li>
                <p>
                    Means the Rhino Affiliates affiliate programme, pursuant to which the Affiliate agrees to promote the Websites.
                </p>
                <li><p className="title">Affiliate Website/s</p></li>
                <p>
                    Any website(s) on the world wide web which is/are maintained, operated or otherwise controlled (whether directly, indirectly or through an Affiliate Network) by the Affiliate.
                </p>
                <li><p className="title">Applicable Law</p></li>
                <p>
                    All laws, statutes, regulations, edicts, bye-laws, mandatory codes of conduct and mandatory guidelines, whether local, national, international or otherwise existing from time to time, which are legally binding on either party and which are applicable to that party's rights or obligations pursuant to this Agreement.
                </p>
                <li><p className="title">Chargeback</p></li>
                <p>
                    The reversal of a payment previously made to us by a Customer, the credit card-issuing bank or any other third party payments solution provider. Chargebacks are regarded as Fraud for the purposes of calculating net revenues.
                </p>
                <li><p className="title">Commencement Date</p></li>
                <p>
                    The date on which we confirm and communicate to you that your application to
                    join the Affiliate Programme has been accepted.
                </p>
                <p></p>
                <li><p className="title">Customer</p></li>
                <p>
                     A new first-time customer of Well Played Media, Unipessoal LDA who has (a) visited an Affiliate Website; (b) been redirected to the Website via a unique link assigned to you as a participant in the Affiliate Programme; (c) created a new player account; and (d) within 90 days of registering a player account, made a first deposit amounting to at least the applicable minimum deposit at the Website, as may be specified by the applicable terms and conditions of the Website from time to time, but excluding you, your employees, relatives and/or friends; and, provided always, that such a customer is not already known to us, or to another entity within the wider group of companies of which we are a part.
                </p>
                <li><p className="title">Fees</p></li>
                <p>
                    The amounts due to you by us as consideration for the provision of the services in accordance with the conditions of this Agreement, calculated pursuant to our revenue share, cost-per-acquisition (“CPA”) or hybrid revenue models, as applicable, and as more particularly communicated to you in any relevant commission schedule.
                </p>
                <li><p className="title">Gross Revenue</p></li>
                <p>
                    The total revenue generated by Customers’ interactions with the Website.
                </p>
                <li><p className="title">Net Revenue</p></li>
                <p>
                    Gross Revenue minus the costs of providing Customers with promotions and bonuses, cost of fraud associated with Customers’ activity on the Website(s), and any progressive jackpot contributions, together with any other applicable administrative and/or network fees.
                </p>
                <li><p className="title">Spam</p></li>
                <p>
                    Unwanted or unsolicited email or SMS, or any other form of communication sent indiscriminately to one or more mailing lists, individuals, or newsgroups, or which otherwise ignores, circumvents, or is in contravention of, recipients’ stated wishes as regards the receipt of such communications.
                </p>
                <li><p className="title">Website/s</p></li>
                <p>
                    The website Casinodays.com, and/or such other websites as may be added to the Affiliate Programme by us from time to time.
                </p>
                </ul>
                    <li>
                    <span>Purpose</span>
                    <p>
                        Well Played Media, Unipessoal LDA is registered as an operator of internet gaming services by the Alcohol and Gaming Commission of Ontario and is licensed to provide remote gambling services via the Website(s) to residents of Ontario, to support this activity Casino Days is promoted via the Affiliate Programme. The Affiliate maintains and operates one or more Affiliate Websites on which it wishes to promote Casino Days, and/or is able to refer potential customers to our Websites via other means/channels. This Agreement sets out the terms upon which the Affiliate may promote the Website, and be entitled to receive Fees for the referral of Customers.
                    </p>
                    <ol type="a">
                        <li>
                        <span>Register your Customer</span>
                        <p>
                            Subject to any registration requirements in place from time to time, we will register any Customers referred by you and track their interactions with the Website. We reserve the right to refuse to provide services to any Customer, or to suspend, or permanently close, their accounts, for any reason whatsoever including, but not limited to, any regulatory obligations we may be subject to. At all material times, Customers remain our customers, and will be treated in accordance with, and be subject to, our rules, policies, and operating procedures. We will not register Customers who are not residents of the Province of Ontario.
                        </p>
                        </li>
                        <li>
                        <span>Modification</span>
                        <p>
                            We may modify any of the terms and conditions contained in this Agreement, or replace it, at any time and in our sole discretion. Modifications may include, for example, changes in the scope of available Fees and Affiliate Programme rules. If any modification is unacceptable to you, your only recourse is to terminate this Agreement. Your continued participation in the Affiliate Programme following our posting of a change notice, or presenting a new agreement to you, will constitute binding acceptance of any and all terms.
                        </p>
                        </li>
                        <li>
                        <span>Your Application</span>
                        <p>
                            To become a member of our Affiliate Programme you will need to accept the terms of this Agreement by completing the Affiliate Programme application form. The application form is an integral part of this Agreement. We will determine, in our sole discretion, whether or not to accept your application and our decision will be final and not subject to any right of appeal. We will notify you by email as to whether or not your application has been successful, and the date of such notification shall be considered the effective date of this Agreement.
                        </p>
                        </li>
                    </ol>
                    </li>
                    <li>
                    <span>Your Rights and Obligations</span>
                    <ol type="a">
                        <li>
                        <span>Promoting our Websites</span>
                        <p>
                            By agreeing to participate in the Affiliate Programme, you are agreeing to create and maintain unique links from the Affiliate Website(s) to the Websites. You may link to the Websites via any banners provided by us, written articles or via a text link. These are the only permitted methods by which you may promote the Websites.
                            <br />
                            Under no circumstances should you promote or advertise, by any means, any form of bonus or promotional offer as being available via the Website(s); and you represent and warrant that you shall not engage in any such activity, which will amount to a material breach of this Agreement and constitute grounds for immediate termination of the same.
                        </p>
                        </li>
                        <li>
                        <span>Spamming</span>
                        <p>
                            We will be entitled to terminate this Agreement immediately, and without recourse to you, in the event that you engage in the distribution of Spam, or if you advertise our services in any way other than as contemplated by this Agreement. You shall not make any claims or representations, or give any warranties, in connection with us and you shall have no authority to, and shall not, bind us to any obligations.
                        </p>
                        </li>
                        <li>
                        <span>Registering of Domain Names</span>
                        <p>
                            You shall also refrain from registering (or applying to register) any domain names or trade marks similar to any domain name or trade mark used by, or registered in the name of, any member of the wider group of companies of which Well Played Media, Unipessoal LDA is a part (“Group”), or any other name that could be understood to designate or represent the Group.
                        </p>
                        </li>
                        <li>
                        <span>Bidding on Brand Terms</span>
                        <p>
                            You may not purchase or register keywords, search terms or other identifiers for use in any search engine, portal, sponsored advertising service or other search or referral service and which are identical or similar to any of the Group’s trade marks, or otherwise include the words "Casino Days", "Casino Days gaming", "Casino Days casino”, or similar, or include metatag keywords on the Affiliate Website which are identical or similar to any of the Group's trade marks.
                        </p>
                        </li>
                        <li>
                        <span>Agency Appointment</span>
                        <p>
                            By this Agreement, we grant you the non-exclusive, non-assignable, right to direct potential Customers to any of our Website(s) in accordance with the terms and conditions of this Agreement. This Agreement does not grant you an exclusive right or privilege to assist us in the provision of services arising from your referrals, and we reserve the right to contract with and obtain the assistance of others, at any time, to perform services of the same or similar nature as yours. You shall have no claim to Fees or other compensation on business secured by or through persons or entities other than you.
                        </p>
                        </li>
                        <li>
                        <span>Approved Layouts</span>
                        <p>
                            You will only use our approved advertising creatives (banners, editorial columns, images and logos) and will not alter their appearance nor refer to us in any promotional materials other than those that are made available to you via the Affiliate Programme. The appearance and syntax of any hypertext transfer links provided to you by us should not be altered or amended in any way.
                        </p>
                        </li>
                        <li>
                        <span>Marketing Guidelines</span>
                        <p>
                            You agree that you will adhere to all policy and guideline documents which we send you from time to time, and the process for handling breaches of this Agreement. In addition, you agree that you will keep up to date with all marketing rules and that you will undertake any online training courses which we may require you to complete.
                            <br />
                            In promoting the Website(s) you shall display the following message, in a legible and comprehensive manner, on the Affiliate Site(s) and/or any other medium used for the promotion of the Website(s):
                            <br />
                            “Gambling is supposed to be fun. If it's not, ConnexOntario can help. 1-866-531-2600.”
                        </p>
                        </li>
                        <li>
                        <span>Good Faith</span>
                        <p>
                            You will not knowingly benefit from known or suspected traffic not generated in good faith whether or not it actually causes us damage. We reserve the right to retain all amounts otherwise due to you under this Agreement if we have reasonable cause to believe there has been such traffic. We reserve the right to withhold Fees and/or suspend or close accounts where affiliated customers are found to be abusing any Casino Days’ offers or promotions whether with or without your knowledge. Such situations to include but not be limited to different customers betting both sides of an event or market so as to limit risk and claim bonuses.
                        </p>
                        </li>
                        <li>
                        <span>Responsibility for Your Site</span>
                        <p>
                            You will be solely responsible for the development, operation, and maintenance of any Affiliate Website and for all materials that appear on the same. For example, you will be solely responsible for ensuring that materials posted on the Affiliate Website are not libellous or otherwise illegal. We disclaim all liability for these matters. Further, you will indemnify and hold us harmless from all claims, damages, and expenses (including, without limitation, legal fees) arising directly or indirectly out of the development, operation, maintenance, and contents of the Affiliate Website(s). The Affiliate Programme is intended for your direct participation, and you shall not open affiliate accounts on behalf of any other participants. Opening an affiliate account for a third party, brokering an affiliate account, or the transfer of an affiliate account is not permitted. Affiliates wishing to transfer an account to another beneficial account owner must request permission to do so by contacting us. Approval is solely at our discretion.
                            <br />
                            Under no circumstances should you promote or advertise the website or services of any third party that provides, or otherwise supports, an unregulated supply of internet gaming services into the Province of Ontario; and you represent and warrant that you shall not engage in any such activity, which will amount to a material breach of this Agreement and constitute grounds for immediate termination of the same.
                        </p>
                        </li>
                        <li>
                        <span>Affiliation</span>
                        <p>
                            You must not state or otherwise infer that there is any partnership, joint venture, agency or franchise relationship between the Affiliate Website(s) and any Websites.
                        </p>
                        </li>
                        <li>
                        <span>Licence to use Marks</span>
                        <p>
                            We hereby grant to you a non-exclusive, non-transferable, revocable licence, during the term of this Agreement, to use our trade name, trade marks, service marks, logos and any other designations, which we may from time to time approve ("Marks") solely in connection with the display of the promotional materials on any Affiliate Website. This licence cannot be sub-licensed, assigned or otherwise transferred by you. Your right to use the Marks is limited to and arises only out of this licence. You shall not assert the invalidity, unenforceability, or contest the ownership of the Marks in any action or proceeding of whatever kind or nature, and shall not take any action that may prejudice our rights in the Marks, render the same generic, or otherwise weaken their validity or diminish their associated goodwill. You must notify us immediately if you become aware of the misuse of the Marks by any third party.
                        </p>
                        </li>
                        <li>
                        <span>Confidential Information</span>
                        <p>
                            During the term of this Agreement, you may be entrusted with confidential information relating to our business, operations, or underlying technology and/or the Affiliate Programme (including, for example, Fees earned by you while participating in the Affiliate Programme). You agree not to disclose, or permit any unauthorised use of, such confidential information to/by any third party unless you have our prior written consent and, further, that you will use the confidential information only for purposes necessary to further the purposes of this Agreement. Your obligations with respect to confidential information shall survive the termination of this Agreement indefinitely.
                        </p>
                        </li>
                        <li>
                        <span>Data Protection</span>
                        <p>
                            You shall at all times comply with all applicable data protection legislation.
                        </p>
                        </li>
                        <li>
                        <span>Licensing Requirements</span>
                        <p>
                            You acknowledge that we are licensed by the Alcohol and Gaming Commission of Ontario (the "Regulator") and, accordingly, we are bound by certain licensing conditions and codes of practice (the "Regulatory Rules"). In addition, we are subject to the requirements of consumer protection legislation.
                        </p>
                        <p>
                            By signing this Agreement and joining the Affiliate Programme, you agree:
                        </p>
                        <ol>
                            <li>
                            (a) to comply with any applicable consumer protection legislation, to observe and comply with all directions and instructions issued by us concerning compliance with consumer protection legislation and to ensure that you will not, in your activities under this Agreement, prejudice, or otherwise interfere with, our compliance with consumer protection legislation. In particular, you shall not alter the appearance, design and content of the our approved banners and text links and/or promotional messaging, or complete any action that will have the effect of: (i) altering or removing any promotional terms and conditions which we include on any banners and text links and/or promotional messaging; or (ii) affecting the means by which a potential Customer may access such promotional terms and conditions from the banners and text links and/or promotional messaging, including the expectation that such promotional terms and conditions must be accessible within a single 'click' of our approved banners and text links and/or promotional messaging or on any linked landing and/or sign-up webpages (or technological equivalent location in non-browser based platforms and technologies such as mobile phone applications); and
                            </li>
                            <li>
                            (b) to provide such information to us as we may reasonably require in order to enable us to comply with our information reporting and other obligations to the Regulator.
                            </li>
                        </ol>
                        </li>
                    </ol>
                    </li>
                    <li>
                    <span>Calculation and Payment of Fees</span>
                    <ol type="a">
                        <li>
                        <span>Right to Payment</span>
                        <p>
                            Casino Days agrees to pay the Affiliate Fees which are calculated with reference to the Net Revenue generated by Customers. Fees will be deemed to be inclusive of value added tax or any other tax if applicable. Fees will be calculated as a percentage of the Net Revenue in accordance with any commission structure which may be communicated to you by us from time to time.
                        </p>
                        </li>
                        <li>
                        <span>Fee Calculations Specifics</span>
                        <p>
                            Negative balances in respect of any revenue share element of the Fees will be deducted from any fixed and/or cost-per-acquisition Fees payable to the Affiliate, if any. Other than in respect of High Rollers (as defined below) or instances of fraud, no negative Fees balances will rollover to the following month and the available Fees will reset to zero each month. If in any given calendar month, a Customer generates negative Net Revenue of at least €10,000 (ten thousand Euro) then such Customer shall be Designated a “High Roller” ("High Roller") going forward. In calculating the Fees due to the Affiliate in respect of High Rollers’ interactions with the Websites, where the calculation of said Fees results in a negative amount in any calendar month, then we shall have the right to carry forward any such negative amounts and the negative amounts will be applicable to and set off against any future Fees payable to you in relation to the High Roller(s) until the negative balance has been fully set off against future positive Fees.
                        </p>
                        </li>
                        <li>
                        <span>Fees Payment</span>
                        <p>
                            Your Fees will be paid to you monthly, subject to the minimum pay out of €500. Fees payments will be paid by bank wire transfer. Please be aware that although we will not deduct any charges for arranging a bank wire, your own bank may choose to levy a charge on such transactions.
                        </p>
                        </li>
                        <li>
                        <span>Payment Currency</span>
                        <p>
                            All Fees will be due and paid in Euros. Where currency conversion is required, all amounts are converted at the prevailing rates of our partner banks and/or financial institutions as at the time of payment.
                        </p>
                        </li>
                    </ol>
                    </li>
                    <li>
                    <span>Term and Termination, Consequences of Termination</span>
                    <ol type="a">
                        <li>
                        <span>Term and Termination</span>
                        <p>
                            The term of this Agreement will begin when you are approved as an affiliate and will be continuous unless and the Agreement is terminated. This Agreement may be terminated by either party by giving a thirty (30) day written notification to the other party. We reserve the right to immediately terminate this Agreement in the event that the Affiliate is in breach of any of its obligations pursuant to the same, and/or of any applicable laws and regulations, and/or if its conduct causes Casino Days and/or a member of its Group to breach any of its licence conditions and/or any applicable laws and/or regulations. These include but are not limited to acts of fraud, spamming etc. For purposes of notification of termination, delivery via email is considered a written and immediate form of notification.
                        </p>
                        </li>
                        <li>
                        <span>Consequences of Termination</span>
                        <p>
                            Upon termination of this Agreement you must remove all of our banners/icons from the Affiliate Website(s) and disable all links from the same to all Websites. All rights and licences given to you in this Agreement shall immediately terminate. You will return to us any confidential information, and all copies of it in your possession, custody and control and will cease all uses of our Marks. In the event this Agreement is terminated by us for cause, we shall not be obliged to pay you any further Fees following the date of termination. For the avoidance of doubt, you shall only be entitled to any Fees which have accrued prior to the date of termination and shall no longer be entitled to any Fees generated by Customers after the date of termination of this Agreement, notwithstanding that they continue to generate revenue for us. In the event this Agreement is terminated for convenience, you shall continue to be entitled to receive Fees for Customers who are active on our Website, subject always to any commission structure communicated to you from time to time.
                        </p>
                        </li>
                        <li>
                        <span>Duplicate Accounts and Self-Referrals</span>
                        <p>
                            You shall not open more than one affiliate account without our prior written consent, nor will you earn Fees on your own or a related person's Affiliate Account as a Customer. The Affiliate Programme is intended for professional website publishers.
                        <br />
                            In the event that duplicate accounts are opened, CPA commissions shall not be paid for the particular players referred using such duplicate accounts, and this shall include payment of CPA commissions under the hybrid option.
                        </p>
                        </li>
                        <li>
                        <span>Self-Excluded Players</span>
                        <p>
                            CPA commissions, including in hybrid payments, shall not be paid in the event that self-excluded players become customers in the same month of registration.
                        </p>
                        </li>
                    </ol>
                    </li>
                    <li>
                    <span>Continued Promotion</span>
                    <p>
                        You shall incorporate and prominently and continually display the most up-to-date links provided by us on all pages of the Affiliate Website(s) in a manner and location agreed by us and you shall not alter the form, location or operation of the links without our prior written consent. You are eligible for Fees based upon your continued promotion of the Websites. 
                        <br />
                        In the event that you fail to refer any new Customers during any consecutive three (3) month period, we reserve the right to reduce your Commission rate to a flat rate of not more than ten percent (10%) of Net Revenue until you introduce a total of three (3) new Customers within a three (3) month period, at which point (effective upon your referral of the third new Customer) your Commission rate will revert to the standard Commission rates set out above.. 
                        <br />
                        Your reduced or suspended promotion of our Websites will be deemed to represent your termination of this Agreement.
                    </p>
                    </li>
                    <li>
                    <span>Relationship of Parties</span>
                    <p>
                        We and you are independent contractors and nothing in this Agreement will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between us. You will have no authority to make or accept any offers or representations on our behalf. You will not make any statement, whether on your site or otherwise, that would contradict anything in this Agreement.
                    </p>
                    </li>
                    <li>
                    <span>Indemnity</span>
                    <p>
                        You shall defend, indemnify, and hold us, our directors, employees and representatives harmless from and against any and all liabilities, losses, damages and costs, including legal fees, resulting from, arising out of, or in any way connected with (a) any breach by you of any warranty, representation or term contained in this Agreement, (b) the performance of your duties and obligations under this Agreement, (c) your negligence or (d) any injury caused directly or indirectly by your negligent or intentional acts or omissions, or the unauthorised use of our banners and links or the Affiliate Programme.
                    </p>
                    </li>
                    <li>
                    <span>Disclaimers</span>
                    <p>
                        We make no express or implied warranties or representations with respect to the Affiliate Programme, about ourselves or the Fees payment arrangements (including, without limitation, functionality, warranties of fitness, merchantability, legality or non-infringement), and do not express nor imply any warranties arising out of a course of performance, dealing, or trade usage. In addition, we make no representation that the operation of our Websites will be uninterrupted or error-free and will not be liable for the consequences if there are any.
                    </p>
                    </li>
                    <li>
                    <span>Limitation of Liability</span>
                    <p>
                        We will not be liable for indirect, special, or consequential damages (or any loss of revenue, profits, or data) arising in connection with this Agreement or the Affiliate Programme, even if we have been advised of the possibility of such damages. Further, our aggregate liability arising with respect to this Agreement and the Affiliate Programme will not exceed the total Fees paid to you pursuant to this Agreement in the 12 (twelve) months immediately preceding the date upon which such liability first arose. Nothing in this Agreement shall be construed to provide any rights, remedies or benefits to any person or entity not a party to this Agreement. Our obligations under this Agreement do not constitute personal obligations of our directors, employees or shareholders. Any liability arising under this Agreement shall be satisfied solely from the Fees generated and is limited to direct damages.
                    </p>
                    </li>
                    <li>
                    <span>Independent Investigation</span>
                    <p>
                        You acknowledge that you have read this Agreement and agree to all its terms and conditions. You understand that we may at any time (directly or indirectly) solicit customer referrals on terms that may differ from those contained in this Agreement or operate or contract with websites that are similar to or compete with the Affiliate Website(s). You have independently evaluated the desirability of participating in this Affiliate Programme and are not relying on any representation, guarantee, or statement other than as set out in this Agreement.
                    </p>
                    </li>
                    <li>
                    <span>Miscellaneous</span>
                    <ol type="a">
                        <li>
                        <span>Governing Law</span>
                        <p>
                            The laws of the Province of Ontario, Canada will govern this Agreement, without reference to rules governing choice of law. Any action relating to this Agreement must be brought in the Province of Ontario and you irrevocably consent to the jurisdiction of its courts.
                        </p>
                        </li>
                        <li>
                        <span>Assignability</span>
                        <p>
                            You may not assign this Agreement, by operation of law or otherwise, without our prior written consent. Subject to that restriction, this Agreement will be binding on, inure to the benefit of, and be enforceable against you and us and our respective successors and assigns.
                        </p>
                        </li>
                        <li>
                        <span>Payment of costs, duties and taxes</span>
                        <p>
                            Each party shall pay its own costs incurred in connection with the negotiation and preparation of this agreement. The Affiliate acknowledges that it is liable for any and all taxes, including VAT, which may be payable in connection with the Agreement.
                        </p>
                        </li>
                        <li>
                        <span>Non-Waiver</span>
                        <p>
                            Our failure to enforce your strict performance of any provision of this Agreement will not constitute a waiver of our right to subsequently enforce such provision or any other provision of this Agreement. No modifications, additions, deletions or interlineations of this Agreement are permitted or will be recognised by us. None of our employees or agents has any authority to make or to agree to any alterations or modifications to this Agreement or its terms.
                        </p>
                        </li>
                        <li>
                        <span>Remedies</span>
                        <p>
                            Our rights and remedies hereunder shall not be mutually exclusive, that is to say that the exercise of one or more of the provisions of this Agreement shall not preclude the exercise of any other provision. You acknowledge, confirm, and agree that damages may be inadequate for a breach or a threatened breach of this Agreement and, in the event of a breach or threatened breach of any provision of this Agreement, we may seek enforcement or compliance by specific performance, injunction, or other equitable remedy. Nothing contained in this Agreement shall limit or affect any of our rights at law, or otherwise, for a breach or threatened breach of any provision of this Agreement, its being the intention of this provision to make clear that our rights shall be enforceable in equity as well as at law or otherwise.
                        </p>
                        </li>
                        <li>
                        <span>Severability/Waiver</span>
                        <p>
                            Whenever possible, each provision of this Agreement shall be interpreted in such a manner as to be effective and valid under applicable law but, if any provision of this Agreement is held to be invalid, illegal or unenforceable in any respect, such provision will be ineffective only to the extent of such invalidity, or unenforceability, without invalidating the remainder of this Agreement or any provision hereof. No waiver will be implied from conduct or failure to enforce any rights and must be in writing to be effective.
                        </p>
                        </li>
                    </ol>
                    </li>
                </ol>
            </div>
        </div>
    )
}
