import React from "react";
import "./style.scss";

export const TermsAndConditions = () => {
  return (
    <div className="terms-content">
      <div className="terms-header">
        <h5 className="terms-title" id="exampleModalLabel">
          TERMS AND CONDITIONS
        </h5>
      </div>
      <div className="terms-body">
        <p>Version 1.2</p>
        <p>Effective Date: 10th of January, 2024</p>
        <p>
          It is important that you (the "Affiliate", "You") read and understand
          these terms and conditions before using these services.
        </p>
        <p>
        The following is an agreement between White Star B.V. (trading as “Casino Days”, “Lucky Spins”, “Buusti Kasino”and “Big Boost” and referred to as “we”, “us” or “our”, as the context requires) and you, being a legal entity applying to register as a member of the Rhino Affiliates programme, which contains the terms and conditions that apply to all members of the Rhino Affiliates programme ("Agreement").
        </p>
        <p>
        When using this website and/or registering as an affiliate with the Rhino Affiliates Programme, you agree to be legally bound by, and comply with, this Agreement.
        </p>
        <p>
          If you do not agree to the following terms and conditions set out in
          this Agreement, you should discontinue your application.
        </p>
        <p>Rhino Affiliates Programme Terms and Conditions</p>
        <ol>
        <li><p className="title">Definitions</p></li>
        <ul>
        <li><p className="title">Affiliate</p></li>
        <p>
        You, the person (whether an individual or a company/corporate entity/organisation) who has registered and been accepted in the Rhino Affiliates Programme.
        </p>
        <li><p className="title">Affiliate Account</p></li>
        <p>An account in the name of the Affiliate on NetRefer.</p>
        <li><p className="title">Affiliate Link/s</p></li>
        <p>
        Internet hyperlinks used by the Affiliate to link from the Affiliate Website/s or any other third-party website to the Websites.
        </p>
        <li><p className="title">Affiliate Network</p></li>
        <p>
        An individual and/or entity with which You have a business relationship with and who operates for You with the intention of driving traffic to the Websites, or that You direct in any appropriate manner to the Websites and who does not have an Affiliate Account directly with Rhino Affiliates but is connected to Your Affiliate Account.
        </p>
        <li><p className="title">Affiliate Payment</p></li>
        <p>Any Revenue Share, Hybrid Payment and/or CPA Payments.</p>
        <li><p className="title">Affiliate Programme</p></li>
        <p>
        Means the Rhino Affiliates affiliate programme, under which the Affiliate agrees to promote the Brand Websites.
        </p>
        <li><p className="title">Affiliate Website/s</p></li>
        <p>
        Any website/s on the world wide web which is/are maintained, operated or otherwise controlled (whether directly, indirectly or through an Affiliate Network) by the Affiliate.
        </p>
        <li><p className="title">Applicable Law</p></li>
        <p>
        All laws, statutes, regulations, edicts, bye-laws, mandatory codes of conduct and mandatory guidelines, whether local, national, international or otherwise existing from time to time, which are legally binding on either Party and which are applicable to that Party's rights or obligations under this Agreement.
        </p>
        <li><p className="title">Balances Carried Over</p></li>
        <p>
        In the calculation of commission where Net Win is negative due to Customer winnings and/or Admin Fees and/or Cash Items and/or Progressive Contributions the said balance will be set to zero. A negative balance due to Fraud Costs will however be carried over where applicable.
        </p>
        <li><p className="title">Chargeback</p></li>
        <p>
        The reversal of a payment made previously to Us by a Customer or the credit card-issuing bank or any other third party payments solution provider. Chargebacks are regarded as Fraud for the purposes of calculating net revenues.
        </p>
        <li><p className="title">Commencement Date</p></li>
        <p>
        The date on which Rhino Affiliates confirms Your application to join the Affiliate Program has been accepted.
        </p>
        <li><p className="title">Customer</p></li>
        <p>
        Means a new first time customer of White Star B.V. who has (a) visited your site(s); (b) who has clicked through to a Website via your click-thru link (c) created a new player account (d) has made a first deposit amounting to at least the applicable minimum deposit at the Website in the Website customer account in accordance with the applicable terms and conditions of the Website, but excluding you, your employees, relatives and/or friends, no later than 90 days from having registered their account with White Star B.V; and (e) is not already in White Star B.V.'s customer database (where the customer has previously closed his White Star B.V. player account and opened a new one through you, such condition shall be deemed not to be satisfied).
        </p>
        <li><p className="title">Fees</p></li>
        <p>
        The amounts due to You by Us in exchange for the provision of the services in accordance with the conditions of this Agreement, calculated under the Revenue Share, CPA, or Hybrid model, as the case may be.
        </p>
        <li><p className="title">Gross Win</p></li>
        <p>
          The total revenue generated by all Products as a result of all bets
          and/or deposits by Customers introduced to Us by You.
        </p>
        <p className="title">Net Win</p>
        <p>
        Gross Win minus bonuses, Fraud Costs, Progressive Contributions and any other admin and network fees.
        </p>
        <li><p className="title">Spam</p></li>
        <p>
        Unwanted or unsolicited email or SMS or any other form of communication sent indiscriminately to one or more mailing lists, individuals, or newsgroups. This shall include not having appropriate opt-ins and/or opt-outs prior to the sending of such communication and the lack of maintaining records of the same.
        </p>
        <li><p className="title">Website/s</p></li>
        <p>
          The websites accessible via the URLs: casinodays.com, luckyspins.com,
          bigboost.com and buustikasino.com, or any other website as may be
          directed by Us.
        </p>
        </ul>
          <li><span>Purpose</span>
            <p>
            White Star B.V. is licensed by the government of Curaçao to provide remote gambling services through its Websites. The Company has elected to promote its Websites via the Rhino Affiliates Programme. The Affiliate maintains and operates one or more websites on the internet (hereinafter collectively referred to as "Affiliate Website"), and/or refers potential customers to the Websites through other channels. This Agreement governs the Terms and Conditions which are related to the promotion of Websites by the Affiliate, whereby the Affiliate will be paid a commission as defined in this Agreement depending on the traffic sent to any of the Websites, pursuant to the terms of this Agreement.
            </p>
            <ol type="a">
              <li>
                <span>Register your Customer</span>
                <p>
                We will register your Customers and track their transactions. We reserve the right to refuse Customers (or to close their accounts) if necessary to comply with any requirements we may periodically establish. By opening an account with us, Customers will become our customers and, accordingly, all of our rules, policies, and operating procedures will apply to them. Please be aware that there are certain jurisdictions from which we cannot, for legal or regulatory reasons, accept business from and as such, no Customers will be able to register from those jurisdictions.
                </p>
              </li>
              <li>
                <span>Modification</span>
                <p>
                We may modify any of the terms and conditions contained in this Agreement or replace it at any time and in our sole discretion by notice to you in writing. Modifications may include, for example, changes in the scope of available Referral Commissions and Affiliate programme rules. If any modification is unacceptable to you, your only recourse is to terminate this Agreement. Your continued participation in our affiliate programme following our posting of a change notice or new agreement to you will constitute binding acceptance of the modification or of the new agreement.
                </p>
              </li>
              <li>
                <span>Your Application</span>
                <p>
                To become a member of our affiliate programme you will need to accept these terms and conditions by completing the application form. The application form will form an integral part of this Agreement. We will in our sole discretion determine whether or not to accept your application and our decision is final and not subject to any right of appeal. We will notify you by email as to whether or not your application has been successful, and the date of such notification shall be considered the effective date of this Agreement.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <span>Your Rights and Obligations</span>
            <ol type="a">
              <li>
                <span>Linking to our Websites</span>
                <p>
                By agreeing to participate in this Affiliate programme, you are agreeing to create and maintain unique links from your site to the websites which are owned, operated or branded by us. You may link to us with any of our banners, articles or with a text link. This is the only method by which you may advertise on our behalf.
                </p>
              </li>
              <li>
                <span>Spamming</span>
                <p>
                We will be entitled to terminate this Agreement immediately without recourse for you if there is any form of spamming or if you advertise our services in any way other than as contemplated by this Agreement. You shall not make any claims or representations, or give any warranties, in connection with us and you shall have no authority to, and shall not, bind us to any obligations.
                </p>
              </li>
              <li>
                <span>Registering of Domain Names</span>
                <p>
                You shall also refrain from registering (or applying to register) any domain names or trademarks similar to any domain name or trademark used by or registered in the name of White Star BV or to any entity within the same group  or any other name that could be understood to designate the group.
                </p>
              </li>
              <li>
                <span>Bidding on Brand Terms</span>
                <p>
                You may not purchase or register keywords, search terms or other identifiers for use in any search engine, portal, sponsored advertising service or other search or referral service and which are identical or similar to any of our group's trade marks, or otherwise include the words "Casino Days", "Casino Days gaming", "Casino Days casino", “Lucky Spins”, “Lucky Spins gaming”, “Lucky Spins casino”, "Buusti Kasino", "Buusti Kasino gaming", “Buusti Kasino casino" and “Big Boost”, “Big Boost gaming” and “Big Boost casino” or similar, or include metatag keywords on the Affiliate Website which are identical or similar to any of our group's trade marks. You will not receive commissions for any customers who are found to have been, or which we have reason to believe have been, referred to any of the Websites by You in breach of this clause.
                </p>
              </li>
              <li>
                <span>Agency Appointment</span>
                <p>
                By this Agreement, we grant you the non-exclusive, non-assignable, right to direct Customers to any of our Website(s) in accordance with the terms and conditions of this Agreement. This Agreement does not grant you an exclusive right or privilege to assist us in the provision of services arising from your referrals, and we obviously intend to contract with and obtain the assistance of others at any time to perform services of the same or similar nature as yours. You shall have no claim to Referral Commission or other compensation on business secured by or through persons or entities other than you.
                </p>
              </li>
              <li>
                <span>Approved Layouts</span>
                <p>
                You will only use our approved advertising creative (banners, editorial columns, images and logos) and will not alter their appearance nor refer to us in any promotional materials other than those that are made available to you via the affiliate platform . The appearance and syntax of the hypertext transfer links are designed and designated by us and constitute the only authorised and permitted representation of our sites.
                </p>
              </li>
              <li>
                <span>Marketing Guidelines</span>
                <p>
                You agree that you will adhere to all policy and guideline documents which we send you from time to time and the process for handling breaches of this Agreement. In addition, you agree that you will keep up to date with all marketing rules and that you will undertake any online training courses which we may require you to complete.
                </p>
              </li>
              <li>
                <span>Good Faith</span>
                <p>
                You will not knowingly benefit from known or suspected traffic not generated in good faith whether or not it actually causes us damage. We reserve the right to retain all amounts otherwise due to you under this Agreement if we have reasonable cause to believe there has been such traffic. We reserve the right to withhold affiliate payments and/or suspend or close accounts where affiliated customers are found to be abusing any Rhino Affiliates offers or promotions whether with or without your knowledge. Such situations to include but not be limited to different customers betting both sides of an event or market so as to limit risk and claim bonuses.
                </p>
              </li>
              <li>
                <span>Responsibility for Your Site</span>
                <p>
                You will be solely responsible for the development, operation, and maintenance of your site and for all materials that appear on your site. For example, you will be solely responsible for ensuring that materials posted on your site are not libellous or otherwise illegal. We disclaim all liability for these matters. Further, you will indemnify and hold us harmless from all claims, damages, and expenses (including, without limitation, legal fees) arising directly or indirectly out of the development, operation, maintenance, and contents of your site. The Rhino Affiliates Programme is intended for your direct participation. You shall not open affiliate accounts on behalf of other participants. Opening an affiliate account for a third party, brokering an affiliate account or the transfer of an affiliate account is not accepted. Affiliates wishing to transfer an account to another beneficial account owner must request permission to do so by contacting us. Approval is solely at our discretion.
                </p>
              </li>
              <li>
                <span>Affiliation</span>
                <p>
                You must not state or otherwise infer that there is any partnership, joint venture, agency or franchise relationship between your site and any Rhino Affiliates website.
                </p>
              </li>
              <li>
                <span>Licence to use Marks</span>
                <p>
                We hereby grant to you a non-exclusive, non-transferable licence, during the term of this Agreement, to use our trade name, trademarks, service marks, logos and any other designations, which we may from time to time approve ("Marks") solely in connection with the display of the promotional materials on your site. This licence cannot be sub-licensed, assigned or otherwise transferred by you. Your right to use the Marks is limited to and arises only out of this licence. You shall not assert the invalidity, unenforceability, or contest the ownership of the Marks in any action or proceeding of whatever kind or nature, and shall not take any action that may prejudice our rights in the Marks, render the same generic, or otherwise weaken their validity or diminish their associated goodwill. You must notify us immediately if you become aware of the misuse of the Marks by any third party.
                </p>
              </li>
              <li>
                <span>Confidential Information</span>
                <p>
                During the term of this Agreement, you may be entrusted with confidential information relating to our business, operations, or underlying technology and/or the Affiliate programme (including, for example, Referral Commissions earned by you under the Affiliate programme). You agree to avoid disclosure or unauthorised use of any such confidential information to third persons or outside parties unless you have our prior written consent and that you will use the confidential information only for purposes necessary to further the purposes of this Agreement. Your obligations with respect to confidential information shall survive the termination of this Agreement.
                </p>
              </li>
              <li>
                <span>Data Protection</span>
                <p>
                You shall at all times comply with all applicable data protection legislation.
                </p>
              </li>
              <li>
                <span>Licensing Requirements</span>
                <p>
                You acknowledge that we are licenced by the Government of Curacao (the "Regulator"), and accordingly we are bound by certain licensing conditions and codes of practice (the "Regulatory Rules"). In addition, we are subject to the requirements of consumer protection legislation.
                </p>
                <p>
                By signing this Agreement and joining the Rhino Affiliates Programme, you agree:
                </p>
                <ol>
                  <li>
                   (a) to comply with any applicable consumer protection legislation, to observe and comply with all directions and instructions issued by us concerning compliance with consumer protection legislation and to ensure that you will not, in your activities under this Agreement, prejudice, or otherwise interfere with, our compliance with consumer protection legislation. In particular, you shall not alter the appearance, design and content of the our approved banners and text links and/or promotional messaging, or complete any action that will have the effect of: (i) altering or removing any promotional terms and conditions which we include on any banners and text links and/or promotional messaging; or (ii) affecting the means by which a potential Customer may access such promotional terms and conditions from the banners and text links and/or promotional messaging, including the expectation that such promotional terms and conditions must be accessible within a single 'click' of the our approved banners and text links and/or promotional messaging or on any linked landing and/or sign-up webpages (or technological equivalent location in non-browser based platforms and technologies such as mobile phone applications); and
                  </li>
                  <li>
                   (b) to provide such information to us as we may reasonably require in order to enable us to comply with our information reporting and other obligations to the Regulator.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <span>Referral Commission Calculation</span>
            <ol type="a">
              <li>
                <span>Pay a Referral Commission</span>
                <p>
                We agree to pay the Affiliate a commission based on the Net Win generated from new customers referred by the Affiliate's website and/or other channel. New customers are those customers of the Company who do not yet have and have not had a betting account with any Website and who have first accessed a Website via a tracking link and who properly register and make real money transfers at least equivalent to the minimum deposit into their account. The commission shall be deemed to be inclusive of value added tax or any other tax if applicable. Each tracking link provided to You is brand/Website specific, and commissions shall be calculated as a percentage of the Net Win generated by referred customers’ activity on the Website to which they were referred via a tracking link, and in accordance with what is set out in the commission structures for the particular product. The calculation is product specific and it is set out in every product-specific commission structure.
                </p>
              </li>
              <li>
                <span>Payable Commission Calculations</span>
                <p>
                Affiliates are eligible for payment on the balance of their games earnings. Negative commission balances in respect of any revenue share element of the Referral Commission will be deducted from available commissions. Other than in respect of High Rollers, no negative commission balances will rollover to the following month and the available commissions will re-set to zero each month. If in any given calendar month, a Customer generates negative commissionable revenue of at least €10,000 (ten thousand Euro) then such player shall be deemed to be a high roller ("High roller(s)"). In calculating the Referral Commission for High Rollers, where commission earned by you in relation to a High Roller(s) results in a negative amount in any calendar month, then we shall have the right to carry forward any such negative amounts and the negative amounts will be applicable to and set off against any future commission payable to you in relation to the High Roller(s) until the negative balance has been fully set off against future positive commission. If any High Roller(s) has a negative commissionable revenue in excess of €10,000 (ten thousand Euro) in the last calendar month prior to the current unbilled calendar month then this negative commissionable revenue will be considered as negative carryover and used to calculate the Referral Commission for the current unbilled calendar month.
                </p>
              </li>
              <li>
                <span>Referral Commission Payments</span>
                <p>
                Your Referral Commission will be paid to you monthly, subject to the minimum pay out of €500. Referral Commission payments will be paid by bank wire transfer. Please be aware that although we will not deduct any charges for arranging a bank wire, your own bank may choose to levy a charge on such transactions.
                </p>
              </li>
              <li>
                <span>Referral Commission Payment Currency</span>
                <p>
                All Referral Commission Payments will be due and paid in Euros. Where currency conversion is required, all amounts are converted at the mid-point applying at the time of payment, as published in the Financial Times. Referral Commissions arising from Customer Accounts that are held in currencies other than the affiliate’s home currency will be converted at the mid-point which applied at the time the Referral Commission was earned.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <span>Term and Termination, Consequences and Unsuitable Sites</span>
            <ol type="a">
              <li>
                <span>Term and Termination</span>
                <p>
                The term of this Agreement will begin when you are approved as an affiliate and will be continuous unless and the Agreement is terminated. This Agreement may be terminated by either party by giving a thirty (30) day written notification to the other party. Written notification may be given by an email. We reserve the right to terminate the agreement if the Affiliate is in material breach of any of its obligations under this Agreement and/or and/or of any applicable laws and regulations and/or if its conduct causes us or any entity within our group to fall in breach of any of its licence conditions and/or any and all applicable laws and regulations. These include but are not limited to acts of fraud, spamming etc. For purposes of notification of termination, delivery via e-mail is considered a written and immediate form of notification. In addition to termination at will by either party, we may terminate this Agreement for cause in the event you breach any of the provisions of this Agreement. Such termination will take effect immediately.
                </p>
              </li>
              <li>
                <span>Consequences of Termination</span>
                <p>
                Upon termination you must remove all of our banners/icons from your site and disable all links from your site to all our Affiliates Websites. All rights and licenses given to you in this Agreement shall immediately terminate. You will return to us any confidential information, and all copies of it in your possession, custody and control and will cease all uses of our Marks. In the event this Agreement is terminated by us for cause, we shall not be obliged to pay you any further Referral Commissions following the date of termination. For the avoidance of doubt, you shall only be entitled to any Referral Commission which has been incurred prior to the date of termination and shall no longer be entitled to any Referral Commission incurred by Customers after the date of termination of this Agreement, notwithstanding that they continue to generate revenue for us. In the event this Agreement is terminated for convenience, you shall continue to be entitled to receive the Referral Commission for Customers who are active on our site, subject always to the fee structure agreed in the Fee Schedule.
                </p>
              </li>
              <li>
                <span>Unsuitable Sites</span>
                <p>
                The term of this Agreement will begin when you are approved as an affiliate and will be continuous unless and the Agreement is terminated. This Agreement may be terminated by either party by giving a thirty (30) day written notification to the other party. Written notification may be given by an email. Either party may terminate this Agreement at any time for convenience, with or without reason. For purposes of notification of termination, delivery via e-mail is considered a written and immediate form of notification. In addition to termination at will by either party, we may terminate this Agreement for cause in the event you breach any of the provisions of this Agreement. Such termination will take effect immediately.
                </p>
              </li>
              <li>
                <span>Duplicate Accounts and Self Referrals</span>
                <p>
                You shall not open more than one affiliate account without our prior written consent nor will you earn commission on your own or related person’s affiliate account. The programme is intended for professional website publishers.
                </p>
                <p>
                In the event that duplicate accounts are opened, CPA commissions shall not be paid for the particular players referred using such duplicate accounts, and this shall include payment of CPA commissions under the hybrid option.
                </p>
              </li>
              <li>
                <span>Self-Excluded Players</span>
                <p>
                CPA commissions, including in hybrid payments, shall not be paid in the event that self-excluded players become customers in the same month of registration.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <span>Continued Promotion</span>
            <p>
            You shall incorporate and prominently and continually display the most up-to-date links provided by us on all pages of your website in a manner and location agreed by us and you shall not alter the form, location or operation of the links without our prior written consent. You are eligible for Referral Commissions based upon your continued promotion of Rhino Affiliates brands. 
            </p>
            <p>
            In the event that you fail to refer any new Customers during any consecutive 3 (three) month period, we reserve the right to reduce your Commission rate to a flat rate of not more than ten percent (10%) of Net Revenue until you introduce a total of three (3) new Customers within a 3 (three) month period, at which point (effective upon your referral of the third new Customer) your Commission rate will revert to the standard Commission rates set out above. 
            </p>
            <p>
            Your reduced or suspended promotion of our sites will be deemed to represent your termination of this Agreement.
            </p>
          </li>
          <li>
            <span>Relationship of Parties</span>
            <p>
            We and you are independent contractors and nothing in this Agreement will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between us. You will have no authority to make or accept any offers or representations on our behalf. You will not make any statement, whether on your site or otherwise, that would contradict anything in this Agreement
            </p>
          </li>
          <li>
            <span>Indemnity</span>
            <p>
            You shall defend, indemnify, and hold us, our directors, employees and representatives harmless from and against any and all liabilities, losses, damages and costs, including legal fees, resulting from, arising out of, or in any way connected with (a) any breach by you of any warranty, representation or term contained in this Agreement, (b) the performance of your duties and obligations under this Agreement, (c) your negligence or (d) any injury caused directly or indirectly by your negligent or intentional acts or omissions, or the unauthorised use of our banners and links or this Affiliate programme.
            </p>
          </li>
          <li>
            <span>Disclaimers</span>
            <p>
            We make no express or implied warranties or representations with respect to the Affiliate programme, about ourselves or the Referral Commission payment arrangements (including, without limitation, functionality, warranties of fitness, merchantability, legality or non-infringement), and do not express nor imply any warranties arising out of a course of performance, dealing, or trade usage. In addition, we make no representation that the operation of our sites will be uninterrupted or error-free and will not be liable for the consequences if there are any.
            </p>
          </li>
          <li>
            <span>Limitation of Liability</span>
            <p>
            We will not be liable for indirect, special, or consequential damages (or any loss of revenue, profits, or data) arising in connection with this Agreement or the affiliate programme, even if we have been advised of the possibility of such damages. Further, our aggregate liability arising with respect to this Agreement and the affiliate programme will not exceed the total Referral Commissions paid or payable to you under this Agreement. Nothing in this Agreement shall be construed to provide any rights, remedies or benefits to any person or entity not a party to this Agreement. Our obligations under this Agreement do not constitute personal obligations of our directors, employees or shareholders. Any liability arising under this Agreement shall be satisfied solely from the Referral Commission generated and is limited to direct damages.
            </p>
          </li>
          <li>
            <span>Independent Investigation</span>
            <p>
            You acknowledge that you have read this Agreement and agree to all its terms and conditions. You understand that we may at any time (directly or indirectly) solicit customer referrals on terms that may differ from those contained in this Agreement or operate or contract with websites that are similar to or compete with your website. You have independently evaluated the desirability of participating in this Affiliate programme and are not relying on any representation, guarantee, or statement other than as set out in this Agreement.
            </p>
          </li>
          <li>
            <span>Miscellaneous</span>
            <ol type="a">
              <li>
                <span>Governing Law</span>
                <p>
                The laws of Curacao will govern this Agreement, without reference to rules governing choice of law. Any action relating to this Agreement must be brought in Curacao and you irrevocably consent to the jurisdiction of its courts.
                </p>
              </li>
              <li>
                <span>Assignability</span>
                <p>
                You may not assign this Agreement, by operation of law or otherwise, without our prior written consent. Subject to that restriction, this Agreement will be binding on, inure to the benefit of, and be enforceable against you and us and our respective successors and assigns.
                </p>
              </li>
              <li>
                <span>Payment of costs, duties and taxes</span>
                <p>
                Each party shall pay its own costs incurred in connection with the negotiation and preparation of this agreement. The Affiliate acknowledges that it is liable for any and all taxes, including VAT, which may be payable in connection with the Agreement.
                </p>
              </li>
              <li>
                <span>Non-Waiver</span>
                <p>
                Our failure to enforce your strict performance of any provision of this Agreement will not constitute a waiver of our right to subsequently enforce such provision or any other provision of this Agreement. No modifications, additions, deletions or interlineations of this Agreement are permitted or will be recognised by us. None of our employees or agents has any authority to make or to agree to any alterations or modifications to this Agreement or its terms.
                </p>
              </li>
              <li>
                <span>Remedies</span>
                <p>
                Our rights and remedies hereunder shall not be mutually exclusive, that is to say that the exercise of one or more of the provisions of this Agreement shall not preclude the exercise of any other provision. You acknowledge, confirm, and agree that damages may be inadequate for a breach or a threatened breach of this Agreement and, in the event of a breach or threatened breach of any provision of this Agreement, we may seek enforcement or compliance by specific performance, injunction, or other equitable remedy. Nothing contained in this Agreement shall limit or affect any of our rights at law, or otherwise, for a breach or threatened breach of any provision of this Agreement, its being the intention of this provision to make clear that our rights shall be enforceable in equity as well as at law or otherwise.
                </p>
              </li>
              <li>
                <span>Severability/Waiver</span>
                <p>
                Whenever possible, each provision of this Agreement shall be interpreted in such a manner as to be effective and valid under applicable law but, if any provision of this Agreement is held to be invalid, illegal or unenforceable in any respect, such provision will be ineffective only to the extent of such invalidity, or unenforceability, without invalidating the remainder of this Agreement or any provision hereof. No waiver will be implied from conduct or failure to enforce any rights and must be in writing to be effective.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};
