import React from "react";
import "./style.scss";
import mainBanner from "../../images/mainbanner.png";
import casinoDays from "../../images/casinodays.png";
import buustiKasino from "../../images/buustikasino.png";
import luckySpins from "../../images/luckyspins.png";
import bigBoost from "../../images/bigboost.png";
import raketti from "../../images/Raketti-Transparent-Pink-800x480.png";
import rocketRiches from "../../images/rocket-riches-logo.png";
import paratiisi from "../../images/paratiisi-logo.png";
import firstIcon from "../../icons/NoCarryOver.svg";
import secondIcon from "../../icons/FastPayments.svg";
import thirdIcon from "../../icons/GreatConversions.svg";
import forthIcon from "../../icons/AccurateReporting.svg";
import envelopeIcon from "../../icons/ContactUs.svg";
import { Link } from "react-router-dom";

export const HomePage = () => {
  return (
    <div className="wrapper">
      <div
        className="main-banner"
        style={{ backgroundImage: `url(${mainBanner})` }}
      >
        <div className="container-fluid">
          <div className="row header-row">
            <div className="col-lg-8 col-md-10">
              <h1>WELCOME TO RHINO AFFILIATES</h1>
            </div>
          </div>
          <div className="row mt-4 description-row">
            <div className="col-lg-7 col-md-7 col-7">
              <h3>
                Start it, build it and grow it with your affiliate partner
                program today
              </h3>
            </div>
          </div>
          <div className="row button-row">
            <div className="col-lg-12 col-md-12">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://affiliates.rhinoaffiliates.com/signup.html"
              >
                <button>Become our Partner</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="why-section" id="why">
        <div className="container-fluid">
          <div className="row border-bottom">
            <div className="col-lg-6 col-12 mb-5">
              <h1>
                WHY <br />
                RHINO
                <br /> AFFILIATES?
              </h1>
            </div>
            <div className="col-lg-6 col-12 mb-5">
              <div className="group big-group">
                <div className="circle">
                  <div className="outside-circle">
                    <div className="circle-border">
                      <span className="content">45%</span>
                    </div>
                  </div>
                </div>
                <h3 className="big-text">HIGH COMMISION</h3>
                <p className="big-text">Earn up to 45% commission each month</p>
              </div>
            </div>
          </div>
          <div className="row row-margin">
            <div className="col-lg-3 col-12 d-none hidden-circle">
              <div className="group">
                <div className="circle small-circle">
                  <div className="outside-circle">
                    <div className="circle-border">
                      <span className="content">45%</span>
                    </div>
                  </div>
                </div>
                <h3 className="small-text">HIGH COMMISION</h3>
                <p className="small-text">
                  Earn up to 45% commission each month
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="group">
                <div className="circle small-circle">
                  <div
                    className="outside-circle"
                    style={{ border: "17px solid #F4AA48" }}
                  >
                    <div className="circle-border">
                      <span className="image">
                        <img src={firstIcon} alt="No Carry Over" />
                      </span>
                    </div>
                  </div>
                </div>
                <h3 className="small-text">NO NEGATIVE CARRY OVER</h3>
                <p className="small-text">
                  Each month is a new chapter in life and we will give you a
                  fresh start.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="group">
                <div className="circle small-circle">
                  <div
                    className="outside-circle"
                    style={{ border: "17px solid #EC6C4A" }}
                  >
                    <div className="circle-border">
                      <span className="image">
                        <img src={secondIcon} alt="Fast Payments" />
                      </span>
                    </div>
                  </div>
                </div>
                <h3 className="small-text">FAST PAYMENTS</h3>
                <p className="small-text">
                  We know the importance of fast payments and will never delay
                  paying your commisions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="group">
                <div className="circle small-circle">
                  <div
                    className="outside-circle"
                    style={{ border: "17px solid #D81271" }}
                  >
                    <div className="circle-border">
                      <span className="image">
                        <img src={thirdIcon} alt="Best Games" />
                      </span>
                    </div>
                  </div>
                </div>
                <h3 className="small-text">GREAT CONVERSIONS</h3>
                <p className="small-text">
                  We have the right payment options for each geo, which means
                  your campaigns will convert better.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12 pr-15">
              <div className="group">
                <div className="circle small-circle">
                  <div
                    className="outside-circle"
                    style={{ border: "17px solid #AB40AD" }}
                  >
                    <div className="circle-border">
                      <span className="image">
                        <img src={forthIcon} alt="Great Mobile" />
                      </span>
                    </div>
                  </div>
                </div>
                <h3 className="small-text">POWERFUL REPORTING</h3>
                <p className="small-text">
                  We use Netrefer's affiliate software giving you access to
                  detailed reports.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="brand-section" id="brands">
        <div className="container-fluid">
          <div className="row border-bottom">
            <div className="col-lg-6 col-12 mb-5">
              <h1 className="brand-header">BRANDS</h1>
            </div>
            <div className="col-lg-6 col-12 mb-5">
              <a href="https://casinodays.com">
                <img className="brand-img" src={casinoDays} alt="Casino Days" />
              </a>
              <p className="brand-content">
                Casino Days is an online casino with a devotion to deliver a
                great entertainment experience. As an affiliate for Casino Days
                you will be promoting one of the best mobile casinos on the
                market. We want you to focus on bringing in customers and we
                will deliver a cutting-edge product with all the features that
                the pickiest casino player could ever ask for. Through great
                segmentation tools and personalized CRM, a Casino Day player
                stays with us longer and will bring good player value that you
                will benefit from.
              </p>
              <a href="https://buustikasino.com">
                <img
                  className="brand-img"
                  src={buustiKasino}
                  alt="Buusti Kasino"
                />
              </a>
              <p className="brand-content">
                Buusti Kasino is a brand new online casino offering players the
                very best bonuses in the market. As a pay n play brand connected
                with all the popular banks in Finland, Buusti allows players to
                create an account, deposit within seconds and have payouts
                awarded in under 5 minutes. Players referred by our marketing
                partners to Buusti Kasino also benefit from a library that is
                more than 4000 games strong. All these aspects combined lead to
                high player retention translating into more revenue for our
                marketing partners.
              </p>
              <a href="https://luckyspins.com">
                <img className="brand-img" src={luckySpins} alt="Lucky Spins" />
              </a>
              <p className="brand-content">
                Lucky Spins is a fresh online casino made for players, by
                players! Boasting a slick design, smooth user interface, and
                more than 5,000 online casino games, Lucky Spins has already
                cemented its name as one of the world's most exciting gambling
                sites. Game providers at Lucky Spins include industry giants
                such as Push Gaming, Play'n Go, and Pragmatic Play, while there
                is also an extensive live casino suite brimming with traditional
                classics and modern twists. Lucky Spins casino also accommodate
                a huge selection of payment methods - the modern-day banking
                sector gets the full representation at Lucky Spins! We
                anticipate great conversion rates and phenomenal customer
                lifetime value with Lucky Spins.
              </p>
              <a href="https://bigboost.com">
                <img className="brand-img" src={bigBoost} alt="Big Boost" />
              </a>
              <p className="brand-content">
                Big Boost stands out as an online casino not only for its
                vibrant colour scheme and design but also the generous bonuses
                it offers to players from around the world. Players can enjoy up
                to 5000 games from their favourite providers and deposit and
                withdraw using a variety of payment methods. Combine this with
                fantastic customer support and we expect the brand to be
                delivering one of the best conversion rates and player values
                for our affiliate partners.
              </p>
              <a href="https://raketti.com">
                <img className="brand-img" src={raketti} alt="Raketti" />
              </a>
              <p className="brand-content">
              We’re proud of our successful brands, but we wanted our newest pay n play brand, and most innovative brand Raketti, to stand out even more. While all our brands have a proven track record of quality and service, we wanted to offer something new that customers across the industry ask for: unforgettable entertainment! Whether you are looking for an impressive selection of games, exciting promotions or just want an excellent casino experience- Raketti has it all! 
              </p>
              <a href="https://rocketriches.com">
                <img className="brand-img" src={rocketRiches} alt="Rocket Riches" />
              </a>
              <p className="brand-content">
              Rocket Riches is an online casino that captivates players with its lively design and engaging atmosphere. 
              With a vast selection of up to 5,000 games from top developers, there's no shortage of entertainment. 
              The casino also offers diverse payment options for quick and secure transactions. 
              Combined with top-tier customer service, we believe Rocket Riches will bring excellent returns and player satisfaction, 
              making it a valuable partner for affiliates.
              </p>
              <a href="https://paratiisi.com">
                <img className="brand-img" src={paratiisi} alt="Paratiisi" />
              </a>
              <p className="brand-content">
              We’re thrilled with the path we've taken so far and with our latest brand, we’re bringing you even closer to a paradise-like experience.
              Introducing Paratiisi! The latest addition to the Rhino family that will elevate user experience to new heights leading to great conversions and even greater value for our marketing partners.
              </p>
            </div>
          </div>
          <div className="row row-margin">
            <div className="col-lg-6 col-12">
              <h3 className="commision-header">COMMISSION<br /> RATE</h3>
            </div>
            <div className="col-lg-6 col-12">
              <h4 className="progress-header">LESS THAN €5000</h4>
              <div className="bars" style={{ width: 55.5 + "%" }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span>25%</span>
                  </div>
                </div>
              </div>
              <h4 className="progress-header">€5000 - €15000</h4>
              <div className="bars" style={{ width: 66.5 + "%" }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="35"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span>30%</span>
                  </div>
                </div>
              </div>
              <h4 className="progress-header">€15000 - €30000</h4>
              <div className="bars" style={{ width: 77.5 + "%" }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span>35%</span>
                  </div>
                </div>
              </div>
              <h4 className="progress-header">€30000 - €40000</h4>
              <div className="bars" style={{ width: 88.5 + "%" }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span>40%</span>
                  </div>
                </div>
              </div>
              <h4 className="progress-header">OVER €40000</h4>
              <div className="bars mb-0" style={{ width: 100 + "%" }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span>45%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-section" id="FAQ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-12">
              <h1>FAQ</h1>
              <p>
                Have a browse through the frequently asked questions. In case
                you were unable to find what you were looking for then please do
                not hesitate to get in touch with our marketing team. They can
                be reached at partners@rhinoaffiliates.com
              </p>
            </div>
            <div className="col-lg-6 col-12">
              <h3>WHAT IS RHINO AFFILIATES?</h3>
              <p className="mb-5">
                Rhino Affiliates is an affiliate program, by joining it you
                become a partner with us. This allows you to earn money through
                your website. By promoting Casino Days, Buusti Kasino, Lucky
                Spins, Big Boost and Raketti on your websites and referring
                players to us - you will earn a cut out of the revenue these
                players generate through deposits.
              </p>
              <h3>HOW DO I GET STARTED?</h3>
              <p className="mb-5">
                A walk in the park my friend! Just type your way through the
                registration form, we’ll validate your account and welcome you
                as a Rhino Affiliate.
              </p>
              <h3>HOW MUCH CAN I EARN?</h3>
              <p className="mb-5">
                It´s all up to you! We do not have a cap on how much you can
                earn.
              </p>
              <h3>WHEN DO I GET PAID?</h3>
              <p className="mb-5">
                We will process all payouts within the third week of each month.
                Please note that we have a minimum earning of €500 before we
                make a pay-out. If your earnings are below €500, your balance
                will automatically transfer to the following month.
              </p>
              <h3>
                FOR HOW LONG DO THE PLAYERS I REFERRED TO RHINO AFFILIATES
                GENERATE MONEY FOR ME?
              </h3>
              <p className="mb-5">
                As long as the player plays with Casino Days, Buusti Kasino,
                Lucky Spins, Big Boost and Raketti, you will earn a cut of the
                revenue the player is generating.
              </p>
              <h3>
                HOW DO RHINO AFFILIATES TRACK THE PLAYERS THAT I’M SENDING TO
                YOU?
              </h3>
              <p className="mb-5">
                As long as you use the links and banners you can find in Media
                Gallery in your Rhino Affiliate account they are connected to
                you by the unique tracking link.
              </p>
              <h3>WHAT HAPPENS IF MY ACCOUNT SHOWS NEGATIVE NET EARNINGS?</h3>
              <p className="mb-5">
                This might happen as a player can win or lose money in the
                casino. However, we do not carry over negative balances from one
                month to another.
              </p>
              <h3>WHAT ARE YOUR TERMS AND CONDITIONS?</h3>
              <p className="mb-5">
                <Link className="modalLink" to="/terms-and-conditions">
                  Right here!
                </Link>
              </p>
              <h3>CAN I SPEAK TO SOMEONE?</h3>
              <p className="mb-5">
                Of course! We´re here for you. Just shoot us an e-mail and we
                will get back as soon as possible.
              </p>
              <h3>DOES IT COST ANY THING TO JOIN RHINO AFFILIATES?</h3>
              <p className="mb-5">
                No. There is no cost of joining the affiliate program.{" "}
              </p>
              <h3>HOW DO YOU TRACK MY PERFORMANCE?</h3>
              <p className="mb-5">
                We use a third-party software called Net Refer that provides
                updated stats every 24 hours.
              </p>
              <h3>WHAT PAYMENT METHODS DO YOU SUPPORT?</h3>
              <p className="mb-5">
                We can currently pay you via Bank transfer, Skrill and NETELLER.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-section" id="contact-us">
        <div className="row">
          <div className="col-lg-6 col-12">
            <h1>
              CONTACT <br />
              US
            </h1>
            <h3>
              DONT BE A STRANGER! PLEASE CONTACT US IF YOU HAVE ANY QUESTIONS.
            </h3>
            <a href="mailto:affiliates@rhinoaffiliates.com">
              partners@rhinoaffiliates.com
            </a>
          </div>
          <div className="col-lg-6 col-12 d-flex justify-content-center">
            <div className="circle">
              <img alt="Contact Us" src={envelopeIcon} />
            </div>
          </div>
        </div>
        <div className="row d-none text-center mobile-mail">
          <div className="col-md-12">
            <a href="mailto:partners@rhinoaffiliates.com">
              partners@rhinoaffiliates.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
