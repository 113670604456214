import React from "react";
import "./style.scss";
import casinoDaysTwoLines from "../../images/Casino-Days-Logo-Two-Lines-Gradient-Text-600x372.png";
import casinoDaysTwoLinesWhite from "../../images/Casino-Days-Logo-Two-Lines-White-Text-600x372.png";
import casinoDaysTwoLinesBg from "../../images/Casino-Days-Two-Lines-Gradient-BG-600x600.png";
import casinoDaysTwoLinesBg2 from "../../images/Casino-Days-Two-Lines-Gradient-BG-800x480.png";
import casinoDaysTwoLinesWhiteBg from "../../images/Casino-Days-Two-Lines-White-BG-600x600.png";
import casinoDaysTwoLinesWhiteBg2 from "../../images/Casino-Days-Two-Lines-White-BG-800x480.png";
import luckySpinsTwoLinesBlack from "../../images/Lucky-Spins-Logo-Two-Lines-Black-Text-480x280.png";
import luckySpinsTwoLinesYellow from "../../images/Lucky-Spins-Logo-Two-Lines-Yellow-Text-480x280.png";
import luckySpinsBGYellow from "../../images/Lucky-Spins-Two-Lines-BG-Yellow-600x600.png";
import luckySpinsBGYellow2 from "../../images/Lucky-Spins-Two-Lines-BG-Yellow-800x480.png";
import luckySpinsPattern600 from "../../images/Lucky-Spins-Two-Lines-Pattern-Yellow-600x600.png";
import luckySpinsPattern800 from "../../images/Lucky-Spins-Two-Lines-Pattern-Yellow-800x480.png";
import buustiKasinoWhite from "../../images/Buusti-Kasino-Logo-White-Text-628x280.png";
import buustiKasinoBlue from "../../images/Buusti-Kasino-Logo-Blue-Text-628x280.png";
import buustiKasinoBgBlue from "../../images/Buusti-Kasino-BG-Blue-600x600.png";
import buustiKasinoBgBlue2 from "../../images/Buusti-Kasino-BG-Blue-800x480.png";
import buustiKasinoBgWhite from "../../images/Buusti-Kasino-BG-White-600x600.png";
import buustiKasinoBgWhite2 from "../../images/Buusti-Kasino-BG-White-800x480.png";
import bigBoostGold from "../../images/Big-Boost-Logo-Two-Lines-Gold-Text-568x352.png";
import bigBoostRed from "../../images/Big-Boost-Logo-Two-Lines-Red-Text-568x352.png";
import bigBoostWhite from "../../images/Big-Boost-Logo-Two-Lines-White-Text-568x352.png";
import bigBoostBgBlue from "../../images/Big-Boost-Two-Lines-BG-Blue-600x600.png";
import bigBoostBgBlue2 from "../../images/Big-Boost-Two-Lines-BG-Blue-800x480.png";
import bigBoostPattern from "../../images/Big-Boost-Two-Lines-BG-Pattern-800x480.png";
import bigBoostBgRed from "../../images/Big-Boost-Two-Lines-BG-Red-600x600.png";
import bigBoostBgRed2 from "../../images/Big-Boost-Two-Lines-BG-Red-800x480.png";
import rakettiTransparentPink from "../../images/Raketti-Transparent-Pink-150x70.png";
import rakettiTransparentYellow from "../../images/Raketti-Transparent-Yellow-150x70.png";
import rakettiTransparentPink600 from "../../images/Raketti-Transparent-Pink-600x600.png";
import rakettiTransparentPink800 from "../../images/Raketti-Transparent-Pink-800x480.png";
import rakettiTransparentYellow600 from "../../images/Raketti-Transparent-Yellow-600x600.png";
import rakettiTransparentYellow800 from "../../images/Raketti-Transparent-Yellow-800x480.png";
import rocketRichesLogo from "../../images/rocket-riches-logo.png";
import rocketRichesH from "../../images/Rocket-Riches-H.png";
import rocketRichesV from "../../images/Rocket-Riches-V.png";
import rocketRichesBgBlack300x300 from "../../images/RR-BG-Black-300x300.png";
import rocketRichesBgBlack400x240 from "../../images/RR-BG-Black-400x240.png";
import rocketRichesBgPink300x300 from "../../images/RR-BG-Pink-300x300.png";
import rocketRichesBgPink400x240 from "../../images/RR-BG-Pink-400x240.png";
import rocketRichesBgYellow300x300 from "../../images/RR-BG-Yellow-300x300.png";
import rocketRichesBgYellow400x240 from "../../images/RR-BG-Yellow-400x240.png";
import rocketRichesGreenText from "../../images/RR-Green-Text-300x140.png";
import rocketRichesPinkText from "../../images/RR-Pink-Text-300x140.png";
import rocketRichesYellowText from "../../images/RR-Yellow-Text-300x140.png";
import paratiisiLogoMandarine256x40 from "../../images/Paratiisi-Logo-Mandarine-Burst-256x40.png";
import paratiisiLogoDragon256x40 from "../../images/Paratiisi-Logo-Dragon-Burst-256x40.png";
import paratiisiBGPattern300x140 from "../../images/Paratiisi-BG-Pattern-300x140.png";
import paratiisiBGPattern200x100 from "../../images/Paratiisi-BG-Paradise-200x100.png";
import paratiisiBGParadise300x140 from "../../images/Paratiisi-BG-Paradise-300x140.png";
import paratiisiBGParadise200x100 from "../../images/Paratiisi-BG-Paradise-200x100.png";
import paratiisiBGMagnolia300x140 from "../../images/Paratiisi-BG-Magnolia-Burst-300x140.png";
import paratiisiBGMagnolia200x100 from "../../images/Paratiisi-BG-Magnolia-Burst-200x100.png";
import paratiisiBGImage300x140 from "../../images/Paratiisi-BG-Image-300x140.png";
import paratiisiBGImage200x100 from "../../images/Paratiisi-BG-Image-200x100.png";
import paratiisiBGBlackberry300x140 from "../../images/Paratiisi-BG-Blackberry-300x140.png";
import paratiisiBGBlackberry200x100 from "../../images/Paratiisi-BG-Blackberry-200x100.png";


export const Downloads = () => {
  return (
    <div className="logos-content">
      <div className="logos-header">
        <h5 className="logos-title">
          Casino Days Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLines} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLines} download='casinoDaysTwoLines'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesWhite} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesWhite} download='casinoDaysTwoLinesWhite'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesBg} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesBg} download='casinoDaysTwoLinesBg'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesBg2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesBg2} download='casinoDaysTwoLinesBg2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesWhiteBg} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesWhiteBg} download='casinoDaysTwoLinesWhiteBg'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesWhiteBg2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesWhiteBg2} download='casinoDaysTwoLinesWhiteBg2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Lucky Spins Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsTwoLinesBlack} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsTwoLinesBlack} download='luckySpinsTwoLinesBlack'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsTwoLinesYellow} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsTwoLinesYellow} download='luckySpinsTwoLinesYellow'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsPattern600} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsPattern600} download='luckySpinsPattern600'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsPattern800} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsPattern800} download='luckySpinsPattern800'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsBGYellow} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsBGYellow} download='luckySpinsBGYellow'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsBGYellow2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsBGYellow2} download='luckySpinsBGYellow2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Buusti Kasino Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoWhite} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoWhite} download='buustiKasinoWhite'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBlue} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBlue} download='buustiKasinoBlue'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBgBlue} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBgBlue} download='buustiKasinoBgBlue'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBgBlue2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBgBlue2} download='buustiKasinoBgBlue2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBgWhite} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBgWhite} download='buustiKasinoBgWhite'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBgWhite2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBgWhite2} download='buustiKasinoBgWhite2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Big Boost Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostGold} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostGold} download='bigBoostGold'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostRed} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostRed} download='bigBoostRed'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostWhite} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostWhite} download='bigBoostWhite'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostBgBlue} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostBgBlue} download='bigBoostBgBlue'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostBgBlue2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostBgBlue2} download='bigBoostBgBlue2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostPattern} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostPattern} download='bigBoostPattern'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostBgRed} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostBgRed} download='bigBoostBgRed'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostBgRed2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostBgRed2} download='bigBoostBgRed2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Raketti Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentPink} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentPink} download='rakettiTransparentPink'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentYellow} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentYellow} download='rakettiTransparentYellow'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentPink600} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentPink600} download='rakettiTransparentPink600'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentPink800} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentPink800} download='rakettiTransparentPink800'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentYellow600} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentYellow600} download='rakettiTransparentYellow600'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentYellow800} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentYellow800} download='rakettiTransparentYellow800'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Rocket Riches Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesLogo} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesLogo} download='rocketRichesLogo'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesH} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesH} download='rochetRichesH'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesV} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesV} download='rocketRichesV'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesBgBlack300x300} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesBgBlack300x300} download='rocketRichesBgBlack300x300'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesBgBlack400x240} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesBgBlack400x240} download='rocketRichesBgBlack400x240'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesBgPink300x300} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesBgPink300x300} download='rocketRichesBgPink300x300'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesBgPink400x240} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesBgPink400x240} download='rocketRichesBgPink400x240'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesBgYellow300x300} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesBgYellow300x300} download='rocketRichesBgYellow300x300'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesBgYellow400x240} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesBgYellow400x240} download='rocketRichesBgYello400x240'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesGreenText} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesGreenText} download='rocketRichesGreenText'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesPinkText} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesPinkText} download='rocketRichesPinkText'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rocketRichesYellowText} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rocketRichesYellowText} download='rocketRichesYellowText'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Paratiisi Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiLogoMandarine256x40} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiLogoMandarine256x40} download='paratiisiLogoMandarine256x40'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiLogoDragon256x40} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiLogoDragon256x40} download='paratiisiLogoDragon256x40'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGPattern300x140} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGPattern300x140} download='paratiisiBGPattern300x140'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGPattern200x100} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGPattern200x100} download='paratiisiBGPattern200x100'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGParadise300x140} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGParadise300x140} download='paratiisiBGParadise300x140'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGParadise200x100} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGParadise200x100} download='paratiisiBGParadise200x100'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGMagnolia300x140} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGMagnolia300x140} download='paratiisiBGMagnolia300x140'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGMagnolia200x100} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGMagnolia200x100} download='paratiisiBGMagnolia200x100'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGImage300x140} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGImage300x140} download='paratiisiBGImage300x140'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGImage200x100} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGImage200x100} download='paratiisiBGImage200x100'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGBlackberry300x140} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGBlackberry300x140} download='paratiisiBGBlackberry300x140'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={paratiisiBGBlackberry200x100} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={paratiisiBGBlackberry200x100} download='paratiisiBGBlackberry200x100'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
    </div>
  );
};
