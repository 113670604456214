import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navigation from './components/navigation'
import HomePage from './components/homePage'
import Footer from './components/footer'
import { Downloads } from './components/downloads'
import { TermsAndConditions } from './components/termsAndConditions'
import { OntarioTermsAndConditions } from './components/termsAndConditions/OntarioTermsAndConditions'
import { Testimonials } from './components/testimonials'
import ScrollReset from './components/ScrollReset'

import './App.scss'

export const App = () => {
  return (
    <BrowserRouter>
      <Navigation/>
      <ScrollReset />
      <Switch>
        <Route exact component={HomePage} path='/'/>
        <Route exact component={TermsAndConditions} path='/terms-and-conditions'/>
        <Route exact component={OntarioTermsAndConditions} path='/ontario-terms-and-conditions'/>
        <Route exact component={Testimonials} path='/testimonials'/>
        <Route exact component={Downloads} path='/downloads'/>
      </Switch>
      <Footer/>
    </BrowserRouter>
  )
}

export default App