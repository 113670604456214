import React, { Component } from 'react'
import logo from '../../images/logo.png'
import { animateScroll as scroll } from "react-scroll"
import './style.scss'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

class Footer extends Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)

    this.state = {
      currentYear: null,
      modalVisible: false
    }
  }

  handleClick() {
    if (!this.state.modalVisible) {
      document.addEventListener('click', this.handleOutsideClick, false)
      this.props.history.push('?terms=true')
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible,
    }))
  }

  handleOutsideClick(e) {
    if(e.target.classList[0] === 'modal' || e.target.classList[0] === 'closebtn') {
      this.props.history.push('')
    }
    this.handleClick()
  }

  scrollToTop = () => {
    scroll.scrollToTop()
  }

  componentDidMount() {
    this.setState({ currentYear: new Date().getFullYear() })
  }

  render() {
    return (
      <footer>
        <div className="row">
          <div className="col-lg-3 col-12 d-flex align-items-center justify-content-center justify-content-lg-start">
            <img onClick={this.scrollToTop} src={logo} alt="Footer Logo" />
          </div>
          <div className="col-lg-9 col-12 d-flex align-items-center justify-content-center justify-content-lg-end terms-col">
            <Link className="termsLink" to='/terms-and-conditions'>General Terms & Conditions</Link>
            <Link className="termsLink" to='/ontario-terms-and-conditions'>Ontario Terms & Conditions</Link>
          </div>
        </div>
      </footer>
    )
  }
}

export default withRouter(Footer)