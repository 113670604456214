import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import logo from '../../images/logo.png'
import './style.scss'
import { Link, animateScroll as scroll } from "react-scroll"

class Navigation extends Component {
  state = {
    isNavOpen: false
  }

  toggleNavigation = () => {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  onHomeHandler = () => {
    const { history } = this.props
    if (history.location.pathname === '/') {
      scroll.scrollToTop()
    } else {
      history.push('/')
    }
  }

  onNavClickHandler = (scrollTo) => {
    const { history } = this.props
    if (history.location.pathname !== '/') {
      history.push('/')
      // scroll to div when page is rendered
      setTimeout(() => {
        const el = document.querySelector(`#${scrollTo}`)
        scroll.scrollTo(el.getBoundingClientRect().top)
      }, 50);
    }
    this.setState({ isNavOpen: false })
  }

  render() {
    return (
      <header>
        <img onClick={this.onHomeHandler} src={logo} alt="Rhino Affiliate" />
        <nav className={`${this.state.isNavOpen} navigation`}>
          <ul className="d-flex">
            {this.state.isNavOpen && <li className="nav-item">
              <a target="_blank" rel="noopener noreferrer" className="nav-link" onclick={this.toggleMobileNavigation} href="https://affiliates.rhinoaffiliates.com/affiliates/Account/Login?ReturnUrl=%2Faffiliates%2F#">Sign In</a>
            </li>}
            <li className="nav-item">
              <Link onClick={() => this.onNavClickHandler('why')} spy={true} smooth={true} offset={-70} duration={500} to="why" className="nav-link">Why</Link>
            </li>
            <li className="nav-item">
              <Link onClick={() => this.onNavClickHandler('brands')} spy={true} smooth={true} offset={-70} duration={500} to="brands" className="nav-link">Brands</Link>
            </li>
            <li className="nav-item">
              <a href="/testimonials" className="nav-link">Testimonials</a>
            </li>
            <li className="nav-item">
              <a href="/downloads" className="nav-link">Downloads</a>
            </li>
            <li className="nav-item">
              <Link onClick={() => this.onNavClickHandler('FAQ')} spy={true} smooth={true} offset={-70} duration={500} to="FAQ" className="nav-link">FAQ</Link>
            </li>
            <li className="nav-item">
              <Link onClick={() => this.onNavClickHandler('contact-us')} spy={true} smooth={true} offset={-70} duration={500} to="contact-us" className="nav-link">Contact Us</Link>
            </li>
          </ul>
          <div className="nav-buttons">
            <a rel="noopener noreferrer" href="http://affiliates.rhinoaffiliates.com/signup.html" target="_blank"><button className="btn gold">Sign Up</button></a>
            <a target="_blank" rel="noopener noreferrer" href="https://affiliates.rhinoaffiliates.com/affiliates/Account/Login?ReturnUrl=%2Faffiliates%2F#"><button className="btn green">Sign In</button></a>
          </div>
        </nav>
        {
          this.state.isNavOpen ?
            <div className="close-nav" onClick={this.toggleNavigation}>
              <i className="fas fa-times times"></i>
            </div>
            :
            <div className="hamburger" onClick={this.toggleNavigation}>
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
        }
      </header>
    )
  }
}

export default withRouter(Navigation);